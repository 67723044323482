<template>
  <div class="farmDetailInfo">
    <h5>جزئیات زمین انتخاب شده</h5>
    <vue-element-loading
      :active="loading"
      text="دریافت جزئیات زمین انتخاب شده..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div v-if="selectedFarm.farmName !== null">
      <div class="farmMapView"></div>
      <div class="farmDetailInfoBox">
        <div class="infoBox">
          <div class="detailInfo">
            <h4>نام زمین</h4>
            <h3>{{ selectedFarm.farmName }}</h3>
          </div>
          <div class="detailInfo">
            <h4>تاریخ کشت</h4>
            <h3>{{ selectedFarm.cultivationsDate }}</h3>
          </div>
          <div class="detailInfo">
            <h4>نوع محصول</h4>
            <h3>{{ selectedFarm.productType }}</h3>
          </div>
          <div class="detailInfo">
            <h4>نام محصول</h4>
            <h3>{{ selectedFarm.productName }}</h3>
          </div>
          <div class="detailInfo">
            <h4>نوع کشت</h4>
            <h3>{{ selectedFarm.cultivationsType }}</h3>
          </div>
        </div>
        <div class="infoBox">
          <div class="detailInfo">
            <h4>استان(شهرستان)</h4>
            <h3>{{ selectedFarm.stateCity }}</h3>
          </div>
          <div class="detailInfo">
            <h4>بخش(روستا)</h4>
            <h3>{{ selectedFarm.villageDistrict }}</h3>
          </div>
          <div class="detailInfo">
            <h4>تعداد متخصص</h4>
            <h3>{{ selectedFarm.advicerCount }}</h3>
          </div>
          <div class="detailInfo">
            <h4>تعداد کارمندان مزرعه</h4>
            <h3>{{ selectedFarm.farmuserCount }}</h3>
          </div>
          <div class="detailInfo">
            <h4>هکتار</h4>
            <h3>{{ selectedFarm.hectares }}</h3>
          </div>
        </div>
      </div>
      <button>
        <i class="fa fa-external-link" aria-hidden="true"></i> کارمندان زمین
      </button>
      <button>
        <i class="fa fa-external-link" aria-hidden="true"></i> متخصصان زمین
      </button>
      <button>
        <i class="fa fa-external-link" aria-hidden="true"></i> اطلاعات کشاورز
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import polyGonStructure from "../../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
export default {
  data() {
    return {
      loading: false,
      selectedFarm: {
        farmName: null,
        cultivationsDate: null,
        productType: null,
        productName: null,
        cultivationsType: null,
        stateCity: null,
        villageDistrict: null,
        advicerCount: null,
        farmuserCount: null,
        hectares: null,
      },
    };
  },
  props: ["farmId"],
  methods: {
    ...mapActions("connectionAgent", ["GetAgentFarmDetail"]),
    async getFarmDetail() {
      if (this.farmId) {
        this.loading = true;
        let result = await this.GetAgentFarmDetail({ id: this.farmId });
        this.loading = false;
        if (result) {
          this.selectedFarm.farmName = result.data.title;
          this.selectedFarm.cultivationsDate = result.data.cultivationsDate;
          this.selectedFarm.productType = result.data.productType;
          this.selectedFarm.productName = result.data.productName;
          this.selectedFarm.cultivationsType =
            result.data.cultivationsTypeTitle;
          this.selectedFarm.stateCity = `${result.data.stateName} (${result.data.cityName})`;
          this.selectedFarm.villageDistrict = `${result.data.district} (${result.data.village})`;
          this.selectedFarm.advicerCount = result.data.advicerCount;
          this.selectedFarm.farmuserCount = result.data.farmuserCount;
          let polygon = result.data.area.substring(
            result.data.area.indexOf("("),
            result.data.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          this.selectedFarm.hectares = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
        } else {
          this.$notify({
            title: "پیام سیستم",
            text: "خطا در دریافت جزئیات زمین <br /><br />",
            type: "error",
          });
        }
      }
    },
  },
  watch: {
    farmId: function () {
      this.getFarmDetail();
    },
  },
  mounted() {
    this.getFarmDetail();
  },
};
</script>

<style scoped>
.farmMapView {
  float: right;
  height: 40vh;
  width: 36vw;
  background-image: url("../../../assets/img/map.png");
  background-size: cover;
  margin: 0px 1% 19px 0px;
  background-repeat: no-repeat;
}
.farmDetailInfo {
  background-color: #fff;
  float: right;
  height: 86vh;
  width: 37vw;
  margin-top: 0vh;
  border: 1px solid #3399ff;
}
.farmDetailInfo h5 {
  float: right;
  width: 100%;
  text-align: center;
  background-color: #3399ff66;
  padding: 2px 0px 2px 0px;
}
.farmDetailInfo button {
  float: right;
  clear: right;
  width: fit-content;
  margin-right: 4%;
  margin-top: 11px;
  background-color: #a1a1a1;
  border: 2px solid #a1a1a1;
  border-right: 3px solid #f80;
}
.farmDetailInfo button:hover {
  background-color: #707070;
  border: 2px solid #f80;
  color: #fff;
}

.farmDetailInfoBox {
  float: right;
  height: 21vh;
  width: 37vw;
}
.infoBox {
  float: right;
  width: 50%;
}
.detailInfo {
  float: right;
  width: 100%;
  margin-top: 2%;
}
.detailInfo h3 {
  float: right;
  font-size: 14px;
  background-color: #e9e9e9;
  width: 61%;
  padding: 2px 3px;
  border: 1px solid #bbb;
  border-right: none;
}
.detailInfo h4 {
  float: right;
  font-size: 14px;
  font-family: "Dirooz";
  color: #2f353a;
  width: 35%;
  text-align: left;
  border: 1px solid #bbb;
  padding: 2px 0px;
  margin-right: 3px;
  border-left: none;
  padding-left: 9px;
}
</style>
