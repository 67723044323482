<template>
  <div style="100vw;100vh">
    <div class="allLegalEntitiesList">
      <h5>لیست سازمان های مناطق شما</h5>
      <legalEntityList @selectedLegalEntity="agentSelectedLegalEntity" />
    </div>
    <div class="allLegalEntityFarms">
      <h5>لیست زمین های سازمان انتخاب شده</h5>
      <farmList
        :legalEntityId="legalEntityIdSelected"
        @selectedFarm="agentSelectedFarm"
      />
    </div>
    <div class="detailBox">
      <farmDetail :farmId="farmIdSelected" />
    </div>
  </div>
</template>
    
<script>
import legalEntityList from "./subForm/legalEntityList.vue";
import farmList from "./subForm/farmList.vue";
import farmDetail from "./subForm/farmDetail.vue";
export default {
  components: {
    legalEntityList,
    farmList,
    farmDetail,
  },
  data() {
    return {
      legalEntityIdSelected: null,
      farmIdSelected: null,
    };
  },
  computed: {},
  methods: {
    agentSelectedLegalEntity(item) {
      this.legalEntityIdSelected = item;
    },
    agentSelectedFarm(item) {
      this.farmIdSelected = item;
    },
  },
};
</script>

<style scoped>
.allLegalEntitiesList {
  float: right;
  height: 86vh;
  width: 18vw;
  margin-top: 2vh;
  margin-right: 1vw;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allLegalEntitiesList h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.allLegalEntityFarms {
  float: right;
  height: 86vh;
  width: 26vw;
  margin-right: 1vw;
  margin-top: 2vh;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allLegalEntityFarms h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.detailBox {
  float: right;
  height: 86vh;
  width: 37vw;
  margin-right: 10px;
  margin-top: 2vh;
}
</style>